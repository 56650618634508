import 'jquery-validation/dist/jquery.validate.min';

const cartData = $('#cart-data');

if(cartData.data('currentLocale') === 'ro') require('jquery-validation/dist/localization/messages_ro.min');

$.validator.addMethod(
    "regex",
    function(value, element, regx)  {
        if (regx && regx.constructor !== RegExp) {
            regx = new RegExp(regx);
        } else if (regx.global) {
            regx.lastIndex = 0;
        }
        return this.optional(element) || regx.test(value);
    },
    function (regx,  element) {
        return $.validator.format(
            `${cartData.data('invalidRegexTranslation')} {0}`,
            `${$(element).data('invalidInputType')}`);
    }
);

const allRegex = /./,
      telephoneRegex = /^[\+]?[(]?[0-9 ]{3}[)]?[-\s\.]?[0-9 ]{3}[-\s\.]?[0-9 ]{4,12}$/,
      numberRegex = /^[0-9]+$/,
      nameRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]*$/,
      emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const checkRegex = (dis, regexType) => {
    return dis.attr('data-regex') === 'true' ? regexType : allRegex;
};

window.validateForm = (formName = 'form', otherButtons = [], removeRules = '', addRules = '') => {

    let allInputs = [],
        oldPassword = $(`form input[type=password][data-old-password]`) || '',
        firstPassword = $(`form input[type=password][data-new-password]`) || '',
        repeatPassword = $(`form input[type=password][data-repeat-password]`) || '';

    const $form = formName instanceof jQuery ? formName : $(`${formName}`);

    $.each($('form input:not([type=hidden])'), function () {
        let $t = $(this);
        switch ($t.prop('type')) {
            case 'text':
                allInputs[$t.prop('name')] = {
                    'required': true,
                    'regex': checkRegex($t, $t.data('inputType') === 'telephone' ? telephoneRegex : nameRegex),
                    'normalizer': function(value) {
                        return $.trim(value);
                    }
                };
                break;
            case 'email':
                allInputs[$t.prop('name')] = {
                    'required': true, 
                    'minlength': '3', 
                    'email': true, 
                    'regex': checkRegex($t, emailRegex),
                    'normalizer': function(value) {
                        return $.trim(value);
                    }
                };
                break;
            case 'password':
                switch (true) {
                    case $t.is(firstPassword):
                        allInputs[$t.prop('name')] = {
                            'required': true,
                            'minlength': '8',
                            'normalizer': function(value) {
                                return $.trim(value);
                            }
                        };
                        break;
                    case $t.is(oldPassword):
                        allInputs[$t.prop('name')] = {
                            'required': true,
                            'normalizer': function(value) {
                                return $.trim(value);
                            }
                        };
                        break;
                    case $t.is(repeatPassword):
                        allInputs[$t.prop('name')] = {
                            'required': true,
                            'minlength': '8',
                            'equalTo': `#${firstPassword.prop('id')}`,
                            'normalizer': function(value) {
                                return $.trim(value);
                            }
                        };
                        break;
                    default:
                        allInputs[$t.prop('name')] = {
                            'required': true,
                            'normalizer': function(value) {
                                return $.trim(value);
                            }
                        };
                }
                break;
            case 'tel':
                allInputs[$t.prop('name')] = {
                    'required': true,
                    'number': true,
                    'regex': checkRegex($t, telephoneRegex),
                    'normalizer': function(value) {
                        return $.trim(value);
                    }
                };
                break;
            case 'number':
                allInputs[$t.prop('name')] = {
                    'required': true,
                    'regex': checkRegex($t, numberRegex),
                    'number': true,
                    'normalizer': function(value) {
                        return $.trim(value);
                    }
                };
                break;
            // case 'file':
            //     allInputs[$t.prop('name')] = {
            //         'required': true,
            //         'accept': 'image/png, image/jpeg, image/jpg, image/gif, image/webp'
            //         'normalizer': function(value) {
            //            return $.trim(value);
            //         }
            //     };
            //     break;
            default:
                allInputs[$t.prop('name')] = {
                    'required': true,
                    'normalizer': function(value) {
                        return $.trim(value);
                    }
                };
        }
    });

    $.each($('form select, form textarea'), function () {
        allInputs[$(this).prop('name')] = {
            'required': true,
            'normalizer': function(value) {return $.trim(value);
            }
        };
    });

    $.validator.messages.step = $.validator.messages.number;

    let validator = $form.validate({
        rules: allInputs,
        wrapper: 'div',
        focusInvalid: true,
        validClass: 'is-valid',
        errorClass: 'is-invalid',
        ignore: '.d-none, type[hidden], .ignore-form',
        onfocusout: function (element) {
            $(element).valid();
            if ($(element).valid()) {
                $(element).addClass('is-valid');
            }

            $('button[type="submit"]').removeAttr('disabled');
        },
        onkeyup: function (element) {
            $(element).valid();
            if ($(element).valid()) {
                $(element).addClass('is-valid');
            }

            $('button[type="submit"]').removeAttr('disabled');
        },
        highlight: function (element, errorClass, validClass) {
            $(element).removeClass(validClass).addClass(errorClass);
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass(errorClass);
        },
        submitHandler: function (form) {
            $('button[type="submit"]').attr('disabled', true);
            form.submit();
        },
        invalidHandler: function (e, validator) {
            if(validator.errorList.length) {
                $(`a[href='#${$(validator.errorList[0].element).parents('.tab-pane').last().attr('id')}']`).tab('show');
                $(`a[href='#${$(validator.errorList[0].element).closest('.tab-pane').attr('id')}']`).tab('show');
            }
        }
    });

    for (let i = 0; i < removeRules.length; i++) {
        $(`#${removeRules[i].id}`).rules('remove', `${removeRules[i].argument}`);
    }

    for (let i = 0; i < addRules.length; i++) {
        let addId = $(`#${addRules[i].id}`),
            addArgument = addRules[i].argument,
            addValue = addRules[i].value;

        addId.rules('add', {
            [addArgument]: addValue
        });
    }

    for (let i = 0; i <= otherButtons.length; i++) {
        $(otherButtons[i]).on('click', function (e) {
            let existingClickEvent = this.onclick;
            this.onclick = null;
            if (!$(`${formName}`).valid()) {
                e.stopImmediatePropagation();
                e.preventDefault();
            } else {
                $(this).click(existingClickEvent);
            }
        });
    }
    $('select').on('change', function () {
        let t = $(this);
        t.valid();
        if (t.valid()) {
            t.addClass('is-valid');
        }
    });
};

$.each($('form'), function () {
    validateForm($(this));
});